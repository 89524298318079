import { ButtonGroup, Button } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { LLS } from "./itemStatic"

export default function LegalLinks() {
  return (
    <ButtonGroup variant="text" aria-label="legal links" sx={{ mb: 2 }}>
      {LLS.map((link, index) => (
        <Button
          aria-label={`Link to ${link.name}`}
          key={`${index}-${link.name}`}
          variant="text"
          sx={{
            px: 1.5,
            textTransform: "none",
            color: "inherit",
            ":hover": {
              color: "#cec8c8"
            },
            fontSize: 16.5,
            fontWeight: 370
          }}
          to={link.to}
          component={RouterLink}
        >
          {link.name}
        </Button>
      ))}
    </ButtonGroup>
  )
}
