import styled from "@mui/material/styles/styled"
export default styled(props => (
  <div {...props}>
    <span></span>
    <span></span>
    <span></span>
  </div>
))(({ theme, position = "start", color = "secondary", size = 1 }) => ({
  margin: `${theme.spacing(1.5)} 0px`,
  lineHeight: 0,
  display: "flex",
  justifyContent: position,
  "& span": {
    borderRadius: "50%",
    display: "inline-flex",
    height: theme.spacing(size),
    width: theme.spacing(size),
    backgroundColor: theme.palette[color]["dark"],
    opacity: 0.5,
    animation: "ellipsis-dot 1s infinite",
    "&:nth-of-type(1)": {
      animationDelay: "0.0s",
      marginRight: theme.spacing(size / 2)
    },
    "&:nth-of-type(2)": {
      animationDelay: "0.1s",
      marginRight: theme.spacing(size / 2)
    },
    "&:nth-of-type(3)": {
      animationDelay: "0.2s"
    }
  },
  "@keyframes ellipsis-dot": {
    "0%": { opacity: 0.5 },
    "50%": { opacity: 1 },
    "100%": { opacity: 0.5 }
  }
}))
