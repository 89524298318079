import { useState, useEffect } from "react"
import { AppBar, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Toolbar, NavLink, NavLogo, NavMenu, Separator } from "./navElements"
import disableScroll from "disable-scroll"

export default function NavBar() {
  const theme = useTheme()
  const up = useMediaQuery(theme.breakpoints.up("sm"))
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (expanded) {
      disableScroll.on()
    } else {
      disableScroll.off()
    }
  }, [expanded])

  useEffect(() => {
    if (up && expanded) {
      setExpanded(false)
    }
  }, [up, expanded, setExpanded])

  return (
    <>
      <AppBar sx={{ bgcolor: "background.cream" }} elevation={0}>
        <Toolbar>
          <NavLogo aria-label="LIMIT logo" setExpanded={setExpanded} to="/" />
          <NavMenu expanded={expanded} setExpanded={setExpanded}>
            <NavLink
              aria-label={`Link to Resources`}
              expanded={expanded}
              setExpanded={setExpanded}
              to="/resources"
            >
              Resources
            </NavLink>
            <NavLink
              aria-label={`Link to Past Speakers`}
              expanded={expanded}
              setExpanded={setExpanded}
              to="/past-speakers"
            >
              Past Speakers
            </NavLink>
            <NavLink
              aria-label={`Link to Contact`}
              expanded={expanded}
              setExpanded={setExpanded}
              to="/contact"
            >
              Contact
            </NavLink>
          </NavMenu>
        </Toolbar>
      </AppBar>
      <Separator />
    </>
  )
}
