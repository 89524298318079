import XIcon from '@mui/icons-material/X';
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded"
import InstagramIcon from "@mui/icons-material/Instagram"
import YouTubeIcon from "@mui/icons-material/YouTube"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import DiscordIcon from "../Discord-icon"

export const LLS = [
  { name: "Privacy", to: "/resources/privacy" },
  { name: "Terms", to: "/resources/terms" },
  { name: "Refund", to: "/resources/refund" }
]

export const QLS = [
  {
    name: "About",
    to: "/resources/about"
  },
  {
    name: "Exam Info",
    to: "/resources/exam-info"
  },
  {
    name: "Prizes",
    to: "/resources/prize"
  },
  {
    name: "Contact",
    to: "/contact"
  },
  {
    name: "Teams",
    to: "/resources/team"
  }
]

export const SLS = [
  {
    name: "X",
    to: "https://x.com/limit_isibc",
    icon: <XIcon />
  },
  {
    name: "Facebook",
    to: "https://www.facebook.com/limit.isibc",
    icon: <FacebookRoundedIcon />
  },
  {
    name: "Instagram",
    to: "https://www.instagram.com/limit_isibc",
    icon: <InstagramIcon />
  },
  {
    name: "Youtube",
    to: "https://www.youtube.com/channel/UC6xlYZ23XgI_sTXlThyPWjw",
    icon: <YouTubeIcon />
  },
  {
    name: "Discord",
    to: "https://discord.gg/QSrv63JUJf",
    icon: <DiscordIcon />
  },
  {
    name: "LinkedIn",
    to: "https://www.linkedin.com/company/limitisi",
    icon: <LinkedInIcon />
  }
]
