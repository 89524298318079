import { NavLink as RouterLink } from "react-router-dom"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Link from "@mui/material/Link"
import Container from "@mui/material/Container"
import DefaultBar from "@mui/material/Toolbar"
import useMediaQuery from "@mui/material/useMediaQuery"
import { styled, useTheme } from "@mui/material/styles"
import { Squeeze as NavButton } from "hamburger-react"
import { useToolBarHeight } from "../fullHeight"

const NavBox = styled(({ className, expanded, ...props }) => (
  <Stack
    direction={{ xs: "column", sm: "row" }}
    spacing={{ xs: 4, sm: 1 }}
    className={expanded ? `${className} expanded` : className}
    {...props}
  />
))(({ theme }) => {
  const height = useToolBarHeight()

  return {
    position: "fixed",
    left: 0,
    right: 0,
    top: height,
    backgroundColor: theme.palette.background.darkCream,
    height: `calc(100vh - ${height}px)`,
    transition: theme.transitions.create("clip-path", {
      duration: theme.transitions.duration.navOut,
      easing: theme.transitions.easing.nav
    }),
    paddingTop: theme.spacing(6),
    clipPath: "inset(0 0 100% 0)",
    zIndex: theme.zIndex.appBar,
    "&.expanded": {
      clipPath: "inset(0)",
      transition: theme.transitions.create("clip-path", {
        duration: theme.transitions.duration.navIn,
        easing: theme.transitions.easing.nav
      })
    },
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      height: "initial",
      top: "initial",
      left: "initial",
      right: "initial",
      paddingTop: 0,
      backgroundColor: "transparent",
      clipPath: "initial"
    }
  }
})

export const Separator = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar
}))

const Bar = styled(props => <DefaultBar disableGutters {...props} />)(
  ({ theme }) => ({
    justifyContent: "space-between",
    "& .hamburger-react": {
      color: theme.palette.primary.main,
      display: "initial",
      WebkitTapHighlightColor: "transparent",
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    }
  })
)

export function Toolbar(props) {
  return (
    <Container maxWidth="lg">
      <Bar {...props} />
    </Container>
  )
}

export const NavLink = styled(
  ({ expanded, setExpanded, className, ...others }) => {
    const theme = useTheme()
    const up = useMediaQuery(theme.breakpoints.up("sm"))

    return (
      <RouterLink
        tabIndex={up ? "0" : expanded ? "0" : "-1"}
        className={({ isActive }) =>
          isActive ? `${className} active` : className
        }
        onClick={() => setExpanded(false)}
        {...others}
      />
    )
  }
)(({ theme }) => ({
  textDecoration: "none",
  textAlign: "center",
  color: theme.palette.primary.main,
  WebkitTapHighlightColor: "transparent",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1.5)
  },
  "&.active, &:hover.active": {
    color: theme.palette.secondary.main
  },
  "&:hover": {
    color: theme.palette.primary.dark
  }
}))

export function NavLogo({ to, setExpanded, ...others }) {
  const height = useToolBarHeight()

  return (
    <Link
      component={RouterLink}
      sx={{ p: 0.8 }}
      onClick={() => setExpanded(false)}
      to={to}
      {...others}
    >
      <Box
        component="img"
        sx={{
          height: { xs: 0.5 * height, sm: 0.6 * height },
          width: "auto"
        }}
        alt="LIMIT Logo"
        src={`${process.env.PUBLIC_URL}/logo.svg`}
      />
    </Link>
  )
}

export function NavMenu({ expanded, setExpanded, children }) {
  return (
    <>
      <NavButton label="Expand button for other pages" size={22} rounded toggled={expanded} toggle={setExpanded} />
      <NavBox expanded={expanded}>{children}</NavBox>
    </>
  )
}
