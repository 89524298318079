import Stack from "@mui/material/Stack"
import Link from "@mui/material/Link"
import { Link as RouterLink } from "react-router-dom"
import styled from "@mui/material/styles/styled"
import { QLS } from "./itemStatic"

export function QuickLinks() {
  return (
    <Stack alignItems={{ xs: "center", sm: "flex-start" }} spacing={1}>
      {QLS.map((link, index) => (
        <QuickLink key={index} to={link.to} aria-label={`Link to ${link.name}`}>
          {link.name}
        </QuickLink>
      ))}
    </Stack>
  )
}

const QuickLink = styled(props => (
  <Link underline="none" component={RouterLink} {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[300],
  padding: theme.spacing(1),
  transition: theme.transitions.create("color", {
    duration: theme.transitions.duration.shorter,
    easing: theme.transitions.easing.sharp
  }),
  "&:hover": {
    color: theme.palette.grey[500]
  }
}))
