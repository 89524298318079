import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import { Link as RouterLink } from "react-router-dom"

export default function Disclaimer() {
  return (
    <>
      <Typography align="center" sx={{ pb: 1 }} variant="h4">
        Disclaimer
      </Typography>
      <Typography sx={{ pb: 2 }} align="center" variant="body1">
        <strong>LIMIT</strong> is organised by students and research scholars of
        ISI Bangalore. ISI administration has no part in it.
      </Typography>
      <Typography
        sx={{ color: "grey.500", pb: 1 }}
        component="p"
        align="center"
        variant="caption"
      >
        &copy; {new Date().getFullYear()}{" "}
        <Link
          underline="hover"
          component={RouterLink}
          to="/resources/team"
          sx={{ color: "inherit" }}
        >
          LIMIT Team
        </Link>{" "}
        All Rights Reserved
      </Typography>
    </>
  )
}
