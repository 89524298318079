import { lazy, Suspense } from "react"
import Loading from "./components/loadingScreen"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import NavBar from "./components/NavBar"
import theme from "./theme"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Wrapper from "./components/wrapper"
import Footer from "./components/Footer"

const Home = lazy(() => import("./routes/Home"))
const Contact = lazy(() => import("./routes/Contact"))
const Resources = lazy(() => import("./routes/Resources"))
const PastSpeakers = lazy(() => import("./routes/PastSpeakers"))
const NoMatch = lazy(() => import("./routes/NoMatch"))

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Wrapper>
          <NavBar />
          <Routes>
            <Route
              path="/resources/*"
              element={
                <Suspense fallback={<Loading />}>
                  <Resources />
                </Suspense>
              }
            />
            <Route
              path="/past-speakers"
              element={
                <Suspense fallback={<Loading />}>
                  <PastSpeakers />
                </Suspense>
              }
            />
            <Route
              path="/contact"
              element={
                <Suspense fallback={<Loading />}>
                  <Contact />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<Loading />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<Loading />}>
                  <NoMatch />
                </Suspense>
              }
            />
          </Routes>
          <Footer />
        </Wrapper>
      </Router>
    </ThemeProvider>
  )
}
