import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Disclaimer from "./disclaimer"
import LegalLinks from "./legalLinks"

export default function Legal() {
  return (
    <Box sx={{ bgcolor: "primary.dark" }}>
      <Container sx={{ py: 4, textAlign: "center" }}>
        <LegalLinks />
        <Disclaimer />
      </Container>
    </Box>
  )
}
