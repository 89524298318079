import Box from "@mui/material/Box"
import Content from "./content"
import Legal from "./legal"
import Graph from "./graph"

export default function Footer() {
  return (
    <Box
      sx={{
        position: "relative",
        top: 0,
        left: 0,
        bgcolor: "primary.main",
        color: "primary.contrastText",
        pt: { xs: 5, sm: 15 },
        zIndex: 90
      }}
    >
      <Graph />
      <Content />
      <Legal />
    </Box>
  )
}
