import FullHeight from "./fullHeight"
import Ellipsis from "./ellipsisAnimation"

export default function Loading() {
  return (
    <FullHeight
      minMode
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Ellipsis color="primary" />
    </FullHeight>
  )
}
